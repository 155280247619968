import GIZSlider from "../../assets/Images/Logos/GizLogo.png";
import CanalPlusSlider from "../../assets/Images/Logos/CanalLogo.png";
import HamiltonCrescentSlider from "../../assets/Images/Logos/HamiltonLogo.png";
import AldeliaSlider from "../../assets/Images/Logos/AldeliaLogo.png";
import ATRSlider from "../../assets/Images/Logos/ATRLogo.png";
import WatchReportSlider from "../../assets/Images/Logos/WatchReportLogo.png";
import YellowCardSlider from "../../assets/Images/Logos/YellowCardLogo.png";
import MasterNodedSlider from "../../assets/Images/Logos/MasterNodedLogo.png";
import BuckheadSlider from "../../assets/Images/Logos/BuckHeadLogo.png";
import KeplerSlider from "../../assets/Images/Logos/KeplerLogo.png";

const RecentProjectData = [
  {
    thumbnail: GIZSlider,
    titleSecondLine: "GIZ",
    link: "https://www.giz.de/en/html/index.html",
  },
  {
    thumbnail: CanalPlusSlider,
    titleFirstLine: "CANAL",
    titleSecondLine: "PLUS",
    link: "https://www.canalplus.com/",
  },
  {
    thumbnail: HamiltonCrescentSlider,
    titleFirstLine: "HAMILTON",
    titleThirdLine: "CRESCENT",
    link: "https://hamiltonccm.com",
  },
  {
    thumbnail: AldeliaSlider,
    titleFirstLine: "AL",
    titleSecondLine: "DE",
    titleThirdLine: "LIA",
    link: "https://www.aldelia.com/en/",
  },
  {
    thumbnail: ATRSlider,
    titleFirstLine: "ATR",
    titleSecondLine: "CYBER",
    link: "https://atrcyber.com",
  },
  {
    thumbnail: WatchReportSlider,
    titleFirstLine: "WATCH",
    titleSecondLine: "REPORT",
    link: "https://www.watchreport.com",
  },
  {
    thumbnail: YellowCardSlider,
    titleFirstLine: "YELLOW",
    titleSecondLine: "SPORTS",
    link: "https://yellowcard.io/",
  },
  {
    thumbnail: MasterNodedSlider,
    titleSecondLine: "MASTER",
    titleThirdLine: "NODED",
    link: "https://masternoded.com",
  },
  {
    thumbnail: BuckheadSlider,
    titleSecondLine: "BUCK",
    titleThirdLine: "HEAD",
    link: "https://www.buckhead.com/",
  },
  {
    thumbnail: KeplerSlider,
    titleSecondLine: "KEPLER",
    link: "https://kepler.org",
  },
];

export default RecentProjectData;
