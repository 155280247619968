const ServicesSpotlightData = [
    "WEB DEVELOPMENT",
    "LOGO DESIGN",
    "AI SERVICES",
    "BRAND DESIGN",
    "SOCIAL MEDIA",
    "GRAPHIC DESIGN",
    "SOFTWARE ENGINEERING",
    "CONCEPT DEVELOPMENT",
    "VIDEO PRODUCTION",
    "DEVOPS",
  ];

export default ServicesSpotlightData;